.print-area {
  display: none;
  size: A4;
  .print-area-wrapper {
    width: calc(100% - 1.5rem);
  }

  .print-header,
  .print-subheader,
  .print-detail {
      padding: .75rem;

  }

  .print-header {
    padding-bottom: 0;
      img {
          max-height: 30px;
          margin-bottom: 20px !important;
      }
  }
  .print-subheader {
    padding-top: 0;
    margin: 0;
    font-size: 1rem;
}

  .print-detail {
       img {
        max-height: 25px;
       }
       app-product-image + .print-detail-row {
        margin-top: 1rem;
       }
      .print-detail-row {
          display: flex;

          .print-detail-row-label {
              min-width: 150px;
              text-transform: uppercase;
          }

          .print-detail-row-value {
              display: flex;
              flex-direction: column;
              padding: 0 1rem;
          }
      }

      .print-detail-row+.print-detail-row {
          margin-top: 1rem;
      }
  }

  .print-table {
      table {
          width: 100%;
          border-collapse: collapse;
          border-spacing: 0px !important;

          thead {
              background-color: #dcdcdc;
          }

          th,
          td {
              text-align: center;
              padding: 1rem 2rem;
          }

          thead,
          td {
              border: 1px solid rgb(204, 204, 204, 0.7);
          }

          th {
              font-weight: 600;
              text-transform: uppercase;
              color: #616060;
              font-size: .9rem;
          }

          tr:nth-of-type(even) {
              background-color: #fafafa;
          }

          tr:last-of-type {
              border: none;
              background: none;

              td {
                  border: none;
                  background: none;
              }

              td:last-of-type {
                  font-weight: 700;
              }
          }

      }
  }

}

@media print {

  .cdk-overlay-container,
    app-sidebar,
    app-footer,
    app-header,
   .app-body {
        display: none;
    }
    * {
        font-family: Arial,
            Helvetica,
            sans-serif !important;
    }
    .print-area {
        display: flex;
        align-items: flex-start;
        width: calc(100vw - 1.5rem);
        min-width: calc(100vw - 1.5rem);
        max-width: calc(100vw - 1.5rem);
        min-height: calc(100vh - 1.5rem);
        padding: .75rem;
    }
    h2 {
      font-size: 18px;
    }
    p, td, th, .print-detail-row-label, .print-detail-row-value {
      font-size: 12px !important;
    }

  }
