/* Store based theme variables */

:root {
  --ao-primary-color: #4eabac;
  --ao-primary-dark: #309191;
  --ao-primary-darker: #178383;
  --ao-primary-darkest: #151818;
  --ao-secondary-color: #7bcdcdf8;
  --ao-secondary-lighter: #b2e8e8;
  --ao-secondary-lightest: #fffcf6;
  --ao-text-color: #fff;
  --ao-primary-rgb: rgba(78, 171, 172, 1);
  --ao-primary-rgb-intermediate: rgba(78, 171, 172, 0.2);
  --ao-primary-rgb-light: rgba(78, 171, 172, 0.1);
  --ao-primary-rgb-light-2: rgba(78, 171, 172, 0.05);
  --ao-primary-rgb-light-3: rgba(78, 171, 172, 0.025);
  --ao-field-background: #f5f7fa;
  --ao-check-border: rgba(78, 171, 172, 0.4);
  --ao-disabled: #fafafa;
}

.berkeley {
  --ao-primary-color: #f1c4c4;
  --ao-primary-dark: #c38f8f;
  --ao-primary-darker: #ad5f5f;
  --ao-primary-darkest: #200a0a;
  --ao-secondary-light: #ffe8e8;
  // --ao-secondary-lighter: #fff9f9;
  --ao-secondary-lighter: rgba(241, 196, 196, .3);
  --ao-secondary-lightest: #f5f7fa;
  --ao-text-color: #000;
  --ao-primary-rgb: rgba(241, 196, 196, 1);
  --ao-primary-rgb-intermediate: rgba(241, 196, 196, 0.2);
  --ao-primary-rgb-light: rgba(241, 196, 196, 0.1);
  --ao-primary-rgb-light-2: rgba(241, 196, 196, 0.05);
  --ao-primary-rgb-light-2: rgba(241, 196, 196, 0.025);
  --ao-check-border: rgba(241, 196, 196, 0.4);
}

.background {
  background: var(--ao-primary-color) !important;
  color: var(--ao-secondary-lighter) !important;
}
.primary {
  background: var(--ao-secondary-light) !important;
  color: var(--ao-primary-darkest) !important;
}
.secondary {
  background: var(--ao-secondary-lighter) !important;
  color: var(--ao-primary-darkest) !important;
}
.alternate {
  background: var(--ao-primary-rgb-light) !important;
  color: var(--ao-primary-darker) !important;
  border: 1px solid var(--ao-secondary-lighter) !important;
}
.intermediate {
  background: var(--ao-primary-rgb-intermediate) !important;
  color: var(--ao-primary-darker) !important;
  border: 1px solid var(--ao-secondary-lighter) !important;
}
.invert {
  background: var(--ao-primary-darker) !important;
  color: var(--ao-secondary-lightest) !important;
}
.text {
  color: var(--ao-primary-color) !important;
}
.dark {
  color: var(--ao-primary-dark) !important;
}
.darker {
  color: var(--ao-primary-darker) !important;
}
.darkest {
  color: var(--ao-primary-darkest) !important;
}
.icon {
  color: var(--ao-primary-dark) !important;
}
.alert {
  background: #fcfcfc;
  color: var(--ao-primary-darkest) !important;
}
.td-dark {
  background: var(--ao-primary-rgb-light) !important;
  color: var(--ao-primary-darkest) !important;
  font-weight: 600 !important;
}
.th-dark {
  background: var(--ao-primary-color) !important;
  color: var(--ao-primary-darkest) !important;
  font-weight: 600 !important;
}
.tr-light,
.th-light {
  background: var(--ao-secondary-lighter) !important;
  color: var(--ao-primary-darkest) !important;
  font-weight: 500 !important;
}
.loader {
  border-color: var(--ao-secondary-lighter) !important;
  border-left-color: var(--ao-primary-color) !important;
  color: var(--ao-primary-color) !important;
}
.check {
  background: var(--ao-secondary-lightest) !important;
  color: var(--ao-primary-darker) !important;
  border: 1px solid var(--ao-check-border) !important;
}
.check-dark {
  background: var(--ao-primary-dark) !important;
  color: var(--ao-secondary-lightest) !important;
  border: 1px solid var(--ao-secondary-lightest) !important;
}

.mat-snack-bar-container {
  background: var(--ao-primary-darkest) !important;
  color: var(--ao-secondary-lightest) !important;
}


button:disabled {
  // background: var(--ao-primary-rgb-light) !important;
  // color: var(--ao-primary-color) !important;
  // border: 1px solid var(--ao-primary-rgb-lightest) !important;
  mat-icon {
    color: var(--ao-check-border) !important;
  }
}

button {
  position: relative;
  &.flex {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    .indicator {
      position: absolute;
      top: 0;
      left: 0;
      height: 12px;
      width: 12px;
      background-color: yellow;
      border-radius: 50%;
      border: 1px solid var(--ao-primary-rgb-light);
    }
  }

}
