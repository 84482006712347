.d-flex {
  display: flex;
}
.d-flex-full {
  display: flex;
  width: 100%;
}
.d-inline-flex {
  display: inline-flex;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.align-start {
  align-items: flex-start;
}
.align-middle {
  align-items: center;
}
.align-stretch {
  align-items: stretch;
}
.align-end {
  align-items: flex-end;
}
.align-items-center,.align-center {
  align-items: center !important;
}
.justify-start {
  justify-content: flex-start;
}
.justify-stretch {
  justify-content: stretch;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-right {
  justify-content: flex-end;
}
.justify-around {
  justify-content: space-around;
}
.self-end {
  align-self: flex-end;
}
.self-start {
  align-self: flex-start;
}
.no-shrink {
  flex-shrink: 0;
}
