.ao-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  label {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.6;
    margin: 0 15px 5px 0;
    display: inline-flex;
    align-items: center;
  }
  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    height: 20px;
    width: 20px;
    color: white;
    margin: 0 10px 0 0;
    cursor: pointer;
    border: 2px solid var(--ao-primary-dark);
    &.centered {
      margin: 0;
    }
  }
  input[type='checkbox']:checked:after {
    height: 20px;
    width: 20px;
    font-size: 20px;
    line-height: 20px;
    font-family: 'Material Icons';
    content: '\e876';
    background-color: var(--ao-primary-dark);
  }
  &.left {
    align-items: flex-start;
  }
}
td,
app-number-input {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .focus-input {
    cursor: pointer;
    div {
      display: none;
      min-width: 50px;
      padding: 5px 0;
      border-bottom: 1px solid var(--ao-primary-dark);
      input {
        width: auto;
        height: auto;
        padding: 0;
      }
    }
    &.focus,
    &:focus-within {
      div {
        display: inline-block;
      }
      span {
        display: none;
      }
    }
  }

  .selected-input {
    &.focus,
    &:focus-within {
      div {
        display: inline-block;
      }
      span {
        display: none;
      }
    }
  }

  .product-input {
    background: none;
    outline: none;
    max-width: 80px;
    border: none;
    border-bottom: 1px solid var(--ao-primary-dark);
    text-align: center;
  }
}
