.ao-card {
  *:not(mat-icon) {
    font-family: "Open Sans", sans-serif !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0px;
  }

  .mat-card-header {
    a {
      color: var(--ao-primary-dark);
      text-decoration: underline;
    }
  }

  .mat-card-header + .mat-card-content {
    padding-top: 1.5rem;
  }
  .breadcrumb {
    list-style-type: none;
    margin-top: 0;

    .breadcrumb-item {
      display: inline-block;
      position: relative;

      a {
        text-decoration: underline;
        cursor: pointer;
        img {
          max-height: 50px;
          max-width: 75px;
        }
      }
      margin: {
        left: 10px;
        right: 10px;
      }
      &::before {
        top: 50%;
        width: 5px;
        height: 5px;
        left: -13px;
        content: "";
        position: absolute;
        border-radius: 50%;
        background: #a0a0a0;
        transform: translateY(-50%);
      }
      &:first-child {
        margin-left: 0;

        &::before {
          display: none;
        }
        a {
          position: relative;
          i {
            left: 0;
            top: 50%;
            font-size: 16px;
            position: absolute;
            transform: translateY(-50%);
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .search-box {
    width: 300px;
  }

  .select-box,
  .search-box {
    .status,
    .input-search {
      background-color: #f5f7fa;
      padding: 0 0 2px 20px;
      color: var(--blackColor);
      outline: 0 !important;
      border-radius: 30px;
      height: 45px;
      border: 0;
      box-sizing: border-box;
    }

    .input-search {
      width: 100%;
      &::placeholder {
        color: #a9a9c8;
        transition: var(--transition);
      }

      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }

    button {
      top: 50%;
      padding: 0;
      right: 10px;
      border: none;
      line-height: 1;
      cursor: pointer;
      position: absolute;
      font-size: 22px !important;
      transform: translateY(-50%);
      color: var(--heraBlueColor);
      background-color: transparent;
      transition: var(--transition);

      &:hover {
        color: var(--blackColor);
      }
    }
  }

  .relative {
    position: relative;
  }

  tr.pointer:hover {
    background-color: #f5f7fa;
  }

  table.mat-table {
    width: 100%;
    box-shadow: none;
  }

  .ao-paginator-minimal {
    .mat-paginator-range-label {
      display: none !important;
    }
}
}

.ao-card + .ao-card {
  margin-top: 1.5rem;
}

ol.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;



  li {
    list-style-type: none;
  }

  li + li {
    margin-left: 1rem;
  }
}

.badge {
  display: inline-block;
  padding: 0.25rem 1rem;
  border-radius: 6px;
}

.actions-button-sm,
.mat-card-actions {
  margin: 0px !important;
  button {
    border: initial;
    background: initial;
    color: initial;
    outline: none !important;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    color: var(--ao-primary-dark) !important;
    border: none;
    cursor: pointer;
    &:disabled {
      color: var(--ao-secondary-lighter) !important;
    }
    &.icon {
      padding: 0;
    }
  }
  .btn + .btn, .btn {
    margin-left: 0.25rem;
  }
  .btn.icon + .btn.icon, .btn.icon + .order-actions, .order-actions + .order-actions {
    margin-left: 1.5rem;
  }
  input, .input, .order-actions input, .order-actions .input {
    width: 50px;
  }
}

.actions-button-sm {
  display: inline-block !important;
  text-align: center;
  width: 50px !important;
 margin: none !important;
 padding: none !important;
  button {
    width: 50px !important;
    border: initial;
    background: var(--ao-primary-dark) !important;
    color: #fff !important;
    padding: none !important;
    outline: none !important;
    margin: none !important;
    width: initial !important;
  }
}
