/* You can add global styles to this file, and also import other style files */
@charset "utf-8";

// @import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
/*@import './styles/material-theme';*/
// @import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");

@font-face {
    font-family: "futura-book";
    src: url("/assets/fonts/futura-book.eot");
    src: url("/assets/fonts/futura-book.eot#iefix") format("embedded-opentype"), url("/assets/fonts/futura-book.woff") format("woff"), url("/assets/fonts/futura-book.ttf") format("truetype"), url("/assets/fonts/futura-book.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura-Medium';
    src: url('/assets/fonts/Futura-Medium.eot') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaBT-Bold';
    src: url('/assets/fonts/FuturaBT-Bold.eot') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaBT-Bold';
    src: url('/assets/fonts/FuturaBT-Bold.ttf') format('truetype'), url('/assets/fonts/FuturaBT-Bold.svg#FuturaBT-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'futura-book';
    font-weight: normal;
    padding: 0px;
    margin: 0px;
    /*margin-top: 1.5%;*/

}


*:not(mat-icon) {
    font-family: 'futura-book';
    font-weight: normal;
}


.futura-book:not(mat-icon) {
    font-family: 'futura-book' !important;
}

@import 'store';
@import 'flex';
@import 'print';
@import 'table';
@import 'card';
@import 'dialog';
@import 'material';
@import 'forms';

strong {
    font-family: 'Futura-Medium';
}

input,
select,
mat-card-title,
textarea:not(.invoice-notes) {
    text-transform: uppercase;
}

@media print {
    .isPrinting>* {
        display: none;
    }

    .isPrinting {
        display: none;
    }

    .isPrinting app-print-layout {
        display: block;
        position: absolute;
        top: 0;
    }
}

.status-select-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 65px !important;
    animation-name: mat-select-animations !important;
    animation-duration: 0.3s !important;
}

@keyframes mat-select-animations {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.mat-snack-bar-container {
    color: white !important;
}

.mat-snack-bar-container.notification--error {
    background-color: #ff3333;
}

.mat-progress-bar-fill::after {
    background-color: rgb(78, 171, 172) !important;
}

.mat-progress-bar-buffer {
    background-color: #e1f8f3 !important
}


.mx-2 {
    margin: 0px .75rem;
}

.log,
view {
    margin: 5px;
}

.view {
    max-width: 25px;
}

.log {
    max-width: 20px;
}

.mat-card-header-text {
    padding: 0px;
    margin: 0px 0px 1.5rem !important;
}

.pointer {
    cursor: pointer;
}

.mat-dialog-container {
  app-payable-order-dialog {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    display: block;
  }
}

#acknowledge {
  background-color: #fdffb6 !important;
}

.post-it {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

app-ok-cancel-dialog {
  display: block;
}

app-ok-cancel-dialog,  .post-it {
  height: 100%;
}
