.ao-table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 1rem;
  -webkit-overflow-scrolling: touch;

    overflow: {
      x: auto;
      y: hidden;
    }

    *:not(mat-icon) {
      font-family: "Open Sans", sans-serif !important;
    }

    .mat-card-header-text {
      padding: 0px !important;
      margin: 0px !important;
    }


    .ao-header-row {
      .ao-header-cell {
        font-weight: 700;
        &.center {
          text-align: center;
          .mat-sort-header-container {
            justify-content: center;
          }
        }
      }
    }

    .ao-table-row {
      .ao-table-cell {
        color: inherit;
        font: inherit;
        padding: 12px 20px;


        app-product-image {
          display: block;
          img {
            width: 100%;
            max-width: 150px;
            cursor: pointer;
          }
          &.logo {
            max-height: 25px;
          }
        }

        .badge {
          color: #00b69b;
          background: rgba(0, 182, 155, 0.1);

          &.pending {
            color: #ee368c;
            background: rgba(238, 54, 140, 0.1);
          }

          &.completed {
            color: #757fef;
            background: rgba(117, 127, 239, 0.1);
          }
        }

        .name-info {
          font: {
            size: 16px;
            weight: 600;
            family: var(--headingFontFamily);
          }
        }

        &:first-child,
        &:last-child {
          color: var(--heraBlueColor);
        }

        &.center {
          text-align: center;
        }
        &.currency {
          text-align: right;
        }

        &.red {
          background-color: red;
          color: red;
        }
        &.green {
          background-color: green;
          color: green;
        }
        &.yellow {
          background-color: yellow;
          color: yellow;
        }
      }

      &:hover {
        // background: rgba(0, 0, 0, 0.025);
      }
    }


  .mat-header-row {
    background-color: #dcdcdc;
  }

  .mat-footer-row {
    td {
      border: none;
      &.center {
        text-align: center;
      }
      &.filled {
        background-color: var(--ao-primary-rgb-light);
      }
      &.filled-light {
        background-color: var(--ao-primary-rgb-light-2);
      }
      &.filled-lightest {
        background-color: var(--ao-primary-rgb-light-3);
      }
      strong {
        font-weight: 700 !important;
      }
    }
  }

  tr:nth-child(even) {
    background-color: #fcfcfc;
  }

  th {
    text-transform: capitalize;
    font-size: 0.9rem;
    .mat-sort-header-content {
      font-weight: 700;
    }
  }

  th,
  td {
    border: 1px solid #dfdfdf;
  }

  th,
  td:not(.td-input) {
    padding: 0.5rem !important;
  }



  .td-input {
    text-align: center;
    position: relative;
    .inpt {
      width: 75px;
      display: inline-flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      input {
        margin: auto;
        border: none;
        border-radius: 0;
        background: none;
        appearance: none;
        padding: 0;
        outline: none;
        text-transform: initial;
        text-align: center;
        height: 48px;
        width: auto;
        max-width: 100%;
      }
      &.lg {
        width: 125px;
      }
    }
    &.focus,
    &:focus-within {
      background-color: var(--ao-primary-rgb-light);
    }
    &.focus.selected-input {
      padding: 0;
    }
  }


  .border-inherit {
    border: inherit !important;
  }
  .color-inherit {
    color: inherit !important;
  }
}

.w-100 {
  width: 100%;
  box-sizing: border-box;
}

.open-sans {
  *:not(mat-icon) {
    font-family: "Open Sans", sans-serif !important;
  }
}

.mat-menu-panel.log {
  max-width: initial;
  min-height: initial;
  .mat-menu-content {
    overflow:  hidden;
    padding: 0px;
    .ao-log {
      margin: 0;
      padding: 0;
      li {
        padding: .5rem 1rem;
        span {
          white-space: nowrap;
        }
        span + span {
          margin-left: 1.5rem;
        }
      }
      li + li {
        border-top: 1px solid #dfdfdf;
      }
      li:nth-of-type(odd) {
        background-color: #fcfcfc;
      }

    }
  }
}

.ao-log-title {
  margin: 0;
  padding: .4rem .65rem;
  font-size: 1rem;
  font-weight: 700;
  color: var(--ao-primary-dark);
}

ul.ao-log {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

table.ao-log {
  border-radius: 5px;
  border: 1px solid var(--ao-disabled);
  margin: .4rem;
}

table.ao-log,
ul.ao-log {
  font-size: 14px;
width: 100%;
  li {
    padding: 0.65rem 1.15rem;
    border-bottom: 1px solid #eeeeee;

    .log-item,
    .log-date,
    .log-initials {
      margin: 0;
      mat-icon {
        margin-right: .5rem;
      }
    }
    .log-item {
      border-bottom: 1px solid var(--ao-primary-dark);
      padding: 0.5rem 0;
      margin-bottom: 0.5rem !important;
    }
    .log-value {
      padding: .25rem .5rem;
      border-radius: .25rem;
      // background-color: var(--ao-secondary-lightest);
    }

  }

  li:last-of-type {
    border-bottom: none;
  }
  tr:nth-of-type(odd),
  li:nth-of-type(odd) {
    background-color: var(--ao-disabled);
  }

  td {
    max-width: 350px;
    padding: 0.65rem;
    border: none;
  }
  .sig {
    text-transform: uppercase;
    justify-content: flex-end;
    text-align: right;
  }

  mat-icon {
    margin-right: .5rem;
    font-size: 14px;
    height: 14px;
    width: 14px;
  }



}

td.center {
  text-align: center;
}
