.minimal {
  .mat-dialog-container {
    display: block;
    padding: 0 !important;
    width: initial !important;
    height: initial !important;
    min-height: initial !important;
    max-height: 100% !important;
  }
}

.open-sans *:not(mat-icon) {
  font-family: "Open Sans", sans-serif !important;
}

.fix-bottom, .fix-side {
  display: flex;
  position: fixed;
  justify-content: stretch;
  background-color: #ffffff;
}

.fix-bottom {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  flex-direction: column;
  bottom: 50px;
  right: 50px;
  width: 250px;
  z-index: 999;
  transform: translateZ(999px);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  &.auto {
    width: initial;
  }
}

.fix-side {
  border-top-left-radius: 6px;
  border-top-left-radius: 6px;
  display: flex;
  position: fixed;
  top: 200px;
  right: 0px;
  width: 100px;
  z-index: 997;
  transform: translateZ(997px);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    flex-wrap: wrap;
    button {
      display: flex;
      min-height: 60px;
      min-width: 45px;
      margin: 0;
      padding: 0;
      justify-content: center;
      align-items: center;

    }
}

.fix-side + mat-card {
  margin-right: 100px;
}

.notes-form,
.fix-side,
.fix-bottom {
  *:not(mat-icon) {
    font-family: "Open sans", sans-serif;
    font-size: 0.8rem;
  }


  .fix-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
    text-align: right;
    background-color: var(--ao-field-background);
    span {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      mat-icon {
        color: var(--ao-primary-darker);
      }
    }
  }
  .container {
    padding: 12px;
    small {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
  }
  button {
    border: initial;
    background: initial;
    color: initial;
    outline: none !important;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    color: var(--ao-primary-dark) !important;
    border: none;
    cursor: pointer;
    &:disabled {
      color: var(--ao-secondary-lighter) !important;
    }
  }
  .btn + .btn {
    margin-left: 0.25rem;
  }

  &.aside {
    right: 0px;
    width: initial;
    border-top-left-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 6px;
    .fix-actions,
    .container {
      display: none;
    }
    .aside-button {
      padding: 12px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      .mat-icon {
        color: var(--ao-primary-darker);
      }
    }
  }
}

.note-text {
  min-width: 200px !important;
}
.signature {
  width: 75px !important;
  overflow: hidden !important;
  input, .mat-form-field-infix {
    width: 50px !important;
  }
}
